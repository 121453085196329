import { useEffect } from "react";

import { useSetAdConfig } from "_common/hooks/global";
import remoteConfig       from "_services/firebase/fetchRemoteConfig";

const useFetchAdConfig = () => {
	const setAdConfig = useSetAdConfig ();

	const env = process.env.APP_ENV || "prod";

	const table = env !== "prod" ? "advertisement_data_web_test" : "advertisement_data_web";

	const fetchAdConfig = async () => {
		try {
			const list = await remoteConfig.getData ( "sportx", table );

			setAdConfig ( list );
		} catch ( err ) {
			// don't do anything if no config
		}
	};

	useEffect ( () => {
		fetchAdConfig ();
	}, [] );

	return null;
};

export default useFetchAdConfig;
